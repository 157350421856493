import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Reference from '../shared/reference'
import { h2 as H2, h3 as H3 } from '../shared/markdown-defaults'
import { BlogPage } from '../shared/page-commons'
import MetaData from '../shared/meta-data'
import { RESEARCH_LOOKUP } from '../shared/research-area'

export default function DefaultLayout(props) {
  // look at the props passed to this component in the console
  const { frontmatter, fields } = props.data.mdx

  return (
    <BlogPage frontmatter={frontmatter}>
      <MetaData
        isBlogPost
        title={frontmatter.title}
        description={frontmatter.headline}
        image={frontmatter.image_src}
        slug={fields.slug}
        date={frontmatter.date}
        category={frontmatter.categories
          ?.map((key) => RESEARCH_LOOKUP[key])
          .join(', ')}
      />
      <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
      {frontmatter.outcome && (
        <>
          <H2>Outcome</H2>
          <div role="list">
            {frontmatter.outcome.map((outcome) => {
              return <Reference reference={outcome} key={outcome.key} />
            })}
          </div>
        </>
      )}
    </BlogPage>
  )
}

export const query = graphql`
  query BlogPageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        pagetype
        categories
        author
        institution
        banner
        banner_caption
        headline
        image_src
        date
        time
        outcome {
          actCategory
          authors
          booktitle
          editor
          institution
          journal
          key
          kind
          month
          number
          organization
          pages
          publisher
          quarter
          rawBibtex
          title
          type
          url
          volume
          year
        }
      }
    }
  }
`
