import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import LdJson from './ld+json'

const query = graphql`
  query metaTags {
    site {
      siteMetadata {
        siteUrl
        twitter
        author
        icon
      }
    }
  }
`

export default function MetaData(props) {
  const { siteUrl, twitter, author, icon } = useStaticQuery(
    query
  ).site.siteMetadata
  const { title, description, image, slug, isBlogPost, date, category } = props

  const isoString = new Date(date).toISOString()
  const imageUrl = siteUrl + withPrefix(image || icon)

  return (
    <>
      {description && (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      )}

      {isBlogPost && (
        <Helmet>
          <meta property="og:type" content="article" />
          <meta property="article:published_time" content={isoString} />
          <meta property="article:author" content={author} />
          <meta property="article:section" content={category} />
        </Helmet>
      )}

      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />

        <meta name="image" content={imageUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:image" content={imageUrl} />

        {/* OpenGraph tags */}
        {slug && (
          <meta property="og:url" content={siteUrl + withPrefix(slug)} />
        )}

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={image ? 'summary_large_image' : 'summary'}
        />
        <meta name="twitter:creator" content={twitter} />
      </Helmet>
      {isBlogPost && (
        <LdJson>
          {{
            '@context': 'https://schema.org',
            '@type': 'Article',
            mainEntityOfPage: slug
              ? {
                  '@type': 'WebPage',
                  '@id': siteUrl + withPrefix(slug),
                }
              : undefined,
            headline: title,
            description,
            image,
            author: {
              '@type': 'Organization',
              name: author,
            },
            publisher: {
              '@type': 'Organization',
              name: 'European Space Agency',
            },
            datePublished: date ? isoString : undefined,
          }}
        </LdJson>
      )}
    </>
  )
}
