import React, { useState } from 'react'
import styled from 'styled-components'

import { FilterButton, FilterGroup } from './filter'

const ReferenceButtonGroup = styled(FilterGroup)`
  padding-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
`

const StyledReference = styled.div`
  padding-bottom: 0.7rem;
  line-height: 150%;
`

const Title = styled.span`
  font-weight: 600;
  display: inline;
`

const Author = styled.div`
  padding-left: 2rem;
  font-style: normal;
  display: block;
`

const Journal = styled.div`
  padding-left: 2rem;
  display: block;
`

const Tags = styled.div`
  font-size: 0.916rem;
  font-weight: bold;
`

const Category = styled.span`
  color: #303850;
`

const Kind = styled.span`
  color: ${(props) => kindLookup[props.type.toLowerCase()] || '#ada385'};
`

const Year = styled.div`
  display: inline;
`

const BibTexRaw = styled.pre`
  display: block;
  padding: 8px 16px 0px 16px;
  overflow-x: auto;
  font-family: var(--font-mono);
  background-color: var(--background);
  border-radius: 2px;
  tab-size: 4;
`

export const referenceLookup = {
  'Advanced Materials': 'Advanced Materials',
  'Artificial Intelligence': 'Artificial Intelligence',
  Bioengineering: 'Bio-Engineering',
  Biomimetics: 'Biomimetics',
  'Earth System Science': 'Earth System Sciences',
  Energy: 'Energy Systems',
  Physics: 'Fundamental Physics',
  Habitats: 'Habitats',
  Informatics: 'Informatics',
  'Computational Management Science': 'Management Science',
  'Mission Analysis': 'Mission Analysis',
  Nanophotonics: 'Nanophotonics',
  Propulsion: 'Propulsion',
  Astro: 'Astronomy & Astrophysics',
}

const kindLookup = {
  'conference paper': '#8599ad',
  'ariadna final report': '#a385ad',
  'peer reviewed article': '#99ad85',
}

export function Venue(props) {
  const {
    type,
    title,
    booktitle,
    institution,
    organization,
    publisher,
    journal,
    number,
    kind,
    volume,
    pages,
    year,
  } = props.reference

  function formatVolume() {
    if (volume && number) {
      return ' ' + volume + ', no. ' + number
    } else if (volume && !number) {
      return ' ' + volume
    } else if (!volume && number) {
      return ' ' + number
    } else {
      return ''
    }
  }

  const formattedPages = pages ? ': ' + pages : ''

  switch (type) {
    case 'inbook':
      return (
        <Journal>
          <i>
            {booktitle || title}, {publisher}
          </i>{' '}
          <Year>({year})</Year>
        </Journal>
      )
    case 'techreport':
      return (
        <Journal>
          <i>
            {institution || organization}, {kind} {number}
          </i>{' '}
          <Year>({year})</Year>
        </Journal>
      )
    case 'article':
      return (
        <Journal>
          <i>
            {journal || organization}
            {formatVolume()}
          </i>
          {formattedPages} <Year>({year})</Year>
        </Journal>
      )
    case 'inproceedings':
      return (
        <Journal>
          <i>
            {[booktitle, organization, institution, journal]
              .filter((x) => x)
              .join(', ')}
          </i>
          {formattedPages} <Year>({year})</Year>
        </Journal>
      )
    default:
      console.warn(`Incompatible type detected: ${type}!`)
      return null
  }
}

const Reference = React.memo((props) => {
  // The citation style of a reference is loosely inspired by
  // the "Chicago style" citations. To cope with inconsistencies in the bibfiles
  // some liberties have been taken to render a structured and cohesive
  // bibliography

  const [isExpanded, setIsExpanded] = useState(false)

  const { title, actCategory, authors, kind, url, rawBibtex } = props.reference

  function toggleBibtex() {
    setIsExpanded(!isExpanded)
  }

  return (
    <StyledReference role="listitem">
      {kind && actCategory && (
        <Tags>
          {actCategory && <Category>{referenceLookup[actCategory]}</Category>}{' '}
          {kind && <Kind type={kind}>{kind}</Kind>}
        </Tags>
      )}
      <Title>{title}</Title>
      <Author>{authors}</Author>
      <Venue reference={props.reference} />
      <ReferenceButtonGroup>
        <FilterButton as="a" href={url}>
          Download
        </FilterButton>
        <FilterButton onClick={toggleBibtex} active={isExpanded}>
          BibTex
        </FilterButton>
      </ReferenceButtonGroup>
      {isExpanded && <BibTexRaw>{rawBibtex}</BibTexRaw>}
    </StyledReference>
  )
})

export default Reference
